import * as R from 'ramda'
import React from 'react'

import {AwardsListPropTypes} from 'helpers/propTypes'
import {Card, CardContent, Grid} from '@mui/material'
import {scrollTop} from 'helpers/utils'
import useIsMobile from 'hooks/useIsMobile'
import useStyles from './styles'

const AwardsList = ({awards, setSelectedAward}) => {
  const mapIndexed = R.addIndex(R.map)
  const classes = useStyles()
  const isMobile = useIsMobile()

  const handleClick = award => {
    scrollTop()
    setSelectedAward(award)
  }

  return (
    <Grid
      container
      spacing={5}
      style={isMobile ? {marginTop: 0} : {marginTop: 70}}
    >
      {mapIndexed(
        (award, index) => (
          <Grid item xs={12} sm={4} md={3} lg={2} key={index}>
            <Card className={classes.card} onClick={() => handleClick(award)}>
              <img src={award.logo.file.url} alt={`${award.name} logo`} />
              <CardContent>
                <div className={classes.cardTitle}>{award.name}</div>
              </CardContent>
            </Card>
          </Grid>
        ),
        awards,
      )}
    </Grid>
  )
}

AwardsList.propTypes = AwardsListPropTypes

AwardsList.defaultProps = {}

export default AwardsList
