import * as R from 'ramda'
import {graphql} from 'gatsby'
import loadable from '@loadable/component'
import React, {useEffect, useState} from 'react'

import {AwardsPropTypes} from 'helpers/propTypes'
import AwardsHeader from 'components/Awards/Header'
import AwardsList from 'components/Awards/AwardsList'
import BackButton from 'components/Awards/BackButton'
import Section from 'components/UI/Section'
import SEO from 'components/seo'

const Awards = ({pageContext, data, location}) => {
  const [selectedAward, setSelectedAward] = useState(null)
  const countryData = R.path(['contentfulCountry'], data)
  const Decoration = loadable(() => import('components/UI/Decoration'))
  const Decorations = loadable(() => import('components/UI/Decorations'))

  const {
    metaTitle,
    metaDescription,
    shortDescription,
    pageTitle,
    backToAllAwardsCta,
  } = R.pathOr(null, ['allContentfulAwards', 'edges', 0, 'node'], data)

  const awards = R.pathOr(null, ['allContentfulAward', 'nodes'], data)

  const shortDesc = R.pathOr('', ['shortDescription'], shortDescription)

  const clickedAward = R.pathOr(null, ['state', 'clickedAward'], location)

  useEffect(
    () => () => {
      if (clickedAward) {
        setSelectedAward(awards.find(award => award.name === clickedAward.name))
      }
    },
    [clickedAward],
  )

  const pageMetaData = {
    metaTitle: R.pathOr('', ['metaTitle'], metaTitle) || metaTitle,
    metaDescription: R.pathOr('', ['metaDescription'], metaDescription),
    siteMetaData: R.pathOr('', ['siteMetaData'], data),
    genericData: R.pathOr('', ['contentfulGenericData'], data),
  }

  return (
    <>
      <SEO
        pageContext={pageContext}
        countryData={countryData}
        pageMetaData={pageMetaData}
      />
      <Decorations>
        <Decoration webSiteName="keyrus" color="orange" right={0} top={130} />
        <Decoration webSiteName="keyrus" color="blue" left={0} top={154} />
      </Decorations>
      {selectedAward && <BackButton backToAllAwardsCta={backToAllAwardsCta} />}
      <AwardsHeader
        pageTitle={pageTitle}
        description={shortDesc}
        selectedAward={selectedAward}
      />
      {!selectedAward && (
        <Section hasPaddingTop={false}>
          <AwardsList awards={awards} setSelectedAward={setSelectedAward} />
        </Section>
      )}
    </>
  )
}

Awards.propTypes = AwardsPropTypes

Awards.defaultProps = {}

export const pageQuery = graphql`
  query templateAwards($nodeLocale: String, $technicalName: String) {
    allContentfulAwards(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          name
          pageTitle
          metaTitle
          shortDescription {
            shortDescription
          }
          metaDescription {
            metaDescription
          }
          backToAllAwardsCta
        }
      }
    }
    allContentfulAward(filter: {node_locale: {eq: $nodeLocale}}) {
      nodes {
        name
        logo {
          file {
            url
          }
        }
        shortDescription {
          shortDescription
        }
      }
    }
    allContentfulService(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          ...serviceFields
        }
      }
    }
    contentfulCountry(
      technicalName: {eq: $technicalName}
      node_locale: {eq: $nodeLocale}
    ) {
      ...countryDataFields
    }
    siteMetaData: site {
      ...SiteMetadata
    }
    contentfulGenericData {
      genericKeywords {
        content
      }
    }
  }
`

export default Awards
