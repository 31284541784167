import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({fontSizes, palette}) => ({
  backButton: {
    color: palette.primary.main,
    fontWeight: 600,
    fontSize: fontSizes.description,
    fontFamily: 'CamptonSemiBold',
    textDecoration: 'none',
    textTransform: 'none',
  },
}))

export default useStyles
