import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({spacing, breakpoints}) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: 'none',
    cursor: 'pointer',
    '& img': {
      objectFit: 'contain',
      height: 200,
      width: '100%',
      maxWidth: '160px',
      [breakpoints.down('xs')]: {
        marginTop: spacing(2),
        maxWidth: '100%',
      },
    },
  },
  cardTitle: {
    fontWeight: 500,
    fontSize: 20,
    textAlign: 'center',
  },
}))

export default useStyles
